import Button from 'components/Button/Button';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { AssetListPageContext } from 'contexts/assetListPage.context';
import { DownloadLogsModal } from './DownloadLogsModal';

const StyledButtonContainer = styled.div`
  margin-right: 10px;
`;
export default function DownloadAssetLogsButton() {
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const { downloadAssetsButtonDisabled } = useContext(AssetListPageContext);

  const handleDownloadLog = () => {
    setOpenDownloadModal(true);
  };

  return (
    <StyledButtonContainer>
      <Button
        style={{ width: '200px' }}
        outline
        onClick={handleDownloadLog}
        disabled={downloadAssetsButtonDisabled}
      >
        Download Custom Log
      </Button>
      {openDownloadModal && <DownloadLogsModal onClose={() => setOpenDownloadModal(false)} />}
    </StyledButtonContainer>
  );
}
